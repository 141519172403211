import type { SeoProps } from 'src/types';

export const seo: SeoProps = {
  description:
    'Search jobs & career advice with Jobstreet. Find job vacancies across Singapore, Malaysia, Philippines, Indonesia & Vietnam - all with Jobstreet!',
  title:
    'Jobstreet - Search jobs across Singapore, Malaysia, Philippines, Indonesia & Vietnam',
  url: 'https://www.jobstreet.com/',
  robots: 'index,follow',
};
