import type { Brand } from '@seek/melways-sites';
import { Helmet } from 'react-helmet';

import { seo as jdbConfig } from 'src/brands/jobsdb/seo';
import { seo as jsConfig } from 'src/brands/jobstreet/seo';
import { seo as seekConfig } from 'src/brands/seek/seo';
import type { SeoProps } from 'src/types';

const seoProps = (brand: Brand) => {
  switch (brand) {
    case 'jobsdb':
      return jdbConfig;
    case 'jobstreet':
      return jsConfig;
    case 'seek':
      return seekConfig;
    default:
      return seekConfig;
  }
};

export const Seo = ({ brand }: { brand: Brand }) => {
  const {
    description,
    title,
    url,
    robots,
    facebookDomainVerification,
  }: SeoProps = seoProps(brand);

  return (
    <Helmet encodeSpecialCharacters={true}>
      <link rel="canonical" href={url} />
      <meta name="robots" content={robots} />
      <meta name="description" content={description} />
      <meta name="referrer" content="origin" />
      {facebookDomainVerification && (
        <meta
          name="facebook-domain-verification"
          content={facebookDomainVerification}
        />
      )}
      <meta property="og:description" content={JSON.stringify(description)} />
      <meta property="og:title" content={title} />
      <title>{title}</title>
    </Helmet>
  );
};
